'use strict';

const GDDLHelper = require('./helpers/helper');

let atrack;

/**
 * Initialize the GDDL analytics tracker
 */
const initAnalyticsTracker = () => {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
};

/**
 * Get the correct placement parameter in the tool step based on which login form
 * @param {jQuery} $element The login form element or the social login button
 * @returns {string} The placement parameter
 */
const getPlacement = ($element) => {
    if ($element.is('.login-flyout *')) {
        return 'pop-up';
    }

    if ($element.is('.inventory-check-login *')) {
        return 'reservation';
    }

    switch ($('.page').data('action')) {
        case 'Login-Show':
            return 'login page';
        case 'Checkout-Login':
            return 'checkout';
        case 'Account-Register':
            return 'registration page';
        default:
            return 'unknown';
    }
};

/**
 * Initializes the Step 1, tool-start
 */
const initStep1 = () => {
    $(document).on('login:submit', (e, data) => {
        const placement = getPlacement(data.$form);
        sessionStorage.setItem('loginAttempt', placement + '|email');
        atrack.trackEvent({
            event: 'tool-start',
            info: {
                type: 'form',
                name: 'login',
                placement: placement,
                step_number: '',
                step_name: '',
                result: {
                    method: 'email'
                },
                error_type: '',
                error_message: ''
            }
        });
    });
};

/**
 * Initializes the last step, tool-laststep
 */
const initLastStep = () => {
    // We can't use the login:success event here since the user data is not yet available in the DOM when we aren't redirected yet
    // That's why he have to check sessionStorage each time a page is loaded
    // Except for login during reservation, because here the page does not reload after login.
    $(document).on('login-reservation:success', (e, data) => {
        const userData = data.userData;
        atrack.pageInfo = atrack.pageInfo || {};
        atrack.pageInfo.user = {
            clientid: GDDLHelper.getCookie('BrowserId'),
            customerid: userData.id,
            email_sha256: userData.email_sha256,
            status: 'Logged in',
            // ssccid in gddl is sscid from SC/CC, same for sscid in gddl is ssccid in SC/CC
            ssccid: userData.sscid,
            sscid: userData.ssccid
        };
        atrack.trackEvent({
            event: 'tool-laststep',
            info: {
                type: 'form',
                name: 'login',
                placement: 'reservation',
                step_number: 1,
                step_name: 'confirmation',
                result: {
                    method: 'email'
                },
                error_type: '',
                error_message: ''
            }
        });
        sessionStorage.removeItem('loginAttempt');
    });

    const loginAttempt = sessionStorage.getItem('loginAttempt');
    if (!loginAttempt) return;

    const [placement, method] = loginAttempt.split('|');

    atrack.trackEvent({
        event: 'tool-laststep',
        info: {
            type: 'form',
            name: 'login',
            placement: placement,
            step_number: 1,
            step_name: 'confirmation',
            result: {
                method: method
            },
            error_type: '',
            error_message: ''
        }
    });

    sessionStorage.removeItem('loginAttempt');
};

/**
 * Initializes the social register attempt
 */
const initSocialRegisterAttempt = () => {
    $(document).on('click', '.gddl-register-google, .gddl-register-facebook', e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const method = $this.hasClass('gddl-register-google') ? 'google' : 'facebook';
        const placement = getPlacement($this);

        sessionStorage.setItem('socialRegisterAttempt', `${placement}|${method}`);

        location.href = $this.attr('href');
    });
};

/**
 * Initializes the tool steps for the social register attempt
 */
const initStepsSocialsRegisterPage = () => {
    const socialRegisterAttempt = sessionStorage.getItem('socialRegisterAttempt');

    if (!socialRegisterAttempt) return;

    const [placement, method] = socialRegisterAttempt.split('|');
    const $authData = $('.js-auth-data');
    const authenticated = $authData.data('authenticated');

    sessionStorage.removeItem('socialRegisterAttempt');

    if (!authenticated) return;

    atrack.trackEvent({
        event: 'tool-start',
        info: {
            type: 'form',
            name: 'login',
            placement: placement,
            step_number: '',
            step_name: '',
            result: {
                method: method
            },
            error_type: '',
            error_message: ''
        }
    });

    atrack.trackEvent({
        event: 'tool-laststep',
        info: {
            type: 'form',
            name: 'login',
            placement: placement,
            step_number: 1,
            step_name: 'confirmation',
            result: {
                method: method
            },
            error_type: '',
            error_message: ''
        }
    });
};

/**
 * Initializes the error step, tool-error
 */
const initErrorHandler = () => {
    $(document).on('login:error', (e, data) => {
        const loginAttempt = sessionStorage.getItem('loginAttempt');
        if (!loginAttempt) return;

        let [placement, method] = loginAttempt.split('|');

        atrack.trackEvent({
            event: 'tool-error',
            info: {
                type: 'form',
                name: 'login',
                placement: placement,
                step_number: 2,
                step_name: 'confirmation',
                result: {
                    method: method
                },
                error_type: 'user',
                error_message: data.error && data.error.length ? data.error[0] : ''
            }
        });

        sessionStorage.removeItem('loginAttempt');
    });
};

const init = () => {
    initAnalyticsTracker();
    initStep1();
    initLastStep();
    initSocialRegisterAttempt();
    initStepsSocialsRegisterPage();
    initErrorHandler();
};

$(() => {
    init();
});
